$(function() {
  const urlParams = new URLSearchParams(window.location.search);
  const signUp = urlParams.get('sign_up');
  const source = urlParams.get('source');

  if (signUp === 'success' && source) {
    dataLayer.push({
      event: 'sign_up',
      sign_up_flow: source
    })
    const newUrl = window.location.href.replace(/(\?|&)sign_up=success/, '').replace(/(\?|&)source=[^&]*/, '');
    window.history.replaceState({}, document.title, newUrl);
  }
})
